<template>
  <div class="common-picker">
    <div class="content" @click="openPciker">
      <div
        class="label"
        :class="{ bold: !!(pickerLabel || defaultLabel) && single && showLabel }"
      >
        {{ single && showLabel ? pickerLabel || defaultLabel || "请选择" : "请选择" }}
      </div>
      <img
        class="arrow-right"
        src="../../assets/images/common/right-arrow.png"
      />
    </div>
    <!-- 公共 -->
    <div v-if="isShowPicker" class="picker-container">
      <Header text="确定" @textClick="closePicker" />
      <div class="search-container">
        <Search
          class="mb-12"
          v-model="searchTmp"
          @search="searchTotal = searchTmp"
        />
      </div>
      <div
        v-for="(item, index) in pickerData"
        :key="index"
        class="picker-item"
        :class="{
          active: single
            ? typeof item === 'string'
              ? item === value
              : item[valueString] === value
            : value.includes(item[valueString]),
        }"
        @click="
          typeof item !== 'string'
            ? item[valueString] === value
              ? ''
              : onClick(item[valueString], item[labelString])
            : onClick(item, item)
        "
      >
        {{ typeof item === "string" ? item : item[labelString] }}
      </div>
    </div>
    <!-- 定制化 -->
    <!-- 经销商 -->
    <BlockSelect
      class="picker-container"
      v-if="isShowDealerPicker"
      selectType="dealer"
      :single="true"
      :userType="blockSelectUserType"
      v-model="dealerList"
      @confirm="isShowDealerPicker = false"
    />
    <!-- 属地业务员 -->
    <SalesmanSelect
      class="picker-container"
      v-if="isShowBusinessManPicker"
      selectType="salesman"
      :single="true"
      :userType="blockSelectUserType"
      v-model="salesmanList"
      @confirm="isShowBusinessManPicker = false"
    />
    <!-- 终端 -->
    <TerminalSelect
      class="picker-container"
      v-if="isShowTerminalPicker"
      selectType="terminal"
      :single="true"
      :userType="blockSelectUserType"
      v-model="terminalList"
      @confirm="isShowTerminalPicker = false"
    />
    <!-- 作物覆盖 -->
    <PlantSelect
      v-if="isShowPlantPicker"
      class="picker-container"
      @confirm="isShowPlantPicker = false"
      v-model="plantList"
    />
    <!-- 种植大户 -->
    <GrowerSelect
      :single="true"
      class="picker-container"
      selectType="grower"
      :userType="blockSelectUserType"
      v-model="growerList"
      v-if="isShowGrowerModal"
      @confirm="isShowGrowerModal = false"
    />
    <!-- 示范田 -->
    <ModelFieldSelect
      :single="true"
      class="picker-container"
      selectType="modelField"
      :userType="blockSelectUserType"
      v-model="modelFieldList"
      v-if="isShowModelFieldModal"
      @confirm="isShowModelFieldModal = false"
    />
    <!-- 品牌选择 -->
    <BrandSelect
      :single="false"
      class="picker-container"
      selectType="brand"
      :userType="blockSelectUserType"
      v-model="brandList"
      v-if="isShowBrandModal"
      @confirm="isShowBrandModal = false"
    />
    <!-- 收货地址 -->
    <ReceiveSelect
      v-if="isShowReceiveAddress"
      class="picker-container"
      selectType="receive"
      @confirm="isShowReceiveAddress = false"
      :single="true"
      v-model="receiveList"
      :params="params"
    />
    <!-- 卸货覆盖 -->
    <DischargeSelect
      v-if="isShowDischargeAddress"
      class="picker-container"
      selectType="receive"
      @confirm="isShowDischargeAddress = false"
      :single="true"
      v-model="dischargeList"
      :params="params"
    />
    <!-- 产品选择 -->
    <ProductSelect
      v-if="isShowProductSelect"
      class="picker-container"
      @confirm="isShowProductSelect = false"
      :checkPrice="false"
      :single="true"
      v-model="productList"
      :params="params"
    />
  </div>
</template>

<script>
import Header from "@/components/header/headerWithText/index.vue";
import Search from "@/components/common/search/index.vue";
import { commonApi } from "@/utils/api.js";
import BlockSelect from "@/views/select/blockSelect.vue";
import PlantSelect from "@/views/select/plantSelect";
import SalesmanSelect from "@/views/select/blockSelect";
import TerminalSelect from "@/views/select/blockSelect";
import ReceiveSelect from "@/views/select/blockSelect";
import DischargeSelect from "@/views/select/blockSelect";
import GrowerSelect from "@/views/select/blockSelect";
import ModelFieldSelect from "@/views/select/blockSelect";
import BrandSelect from "@/views/select/blockSelect";
// import ProductSelect from '@/views/select/productDetailSelect'
import * as util from "@/utils/util.js";
import { getDealerId } from "@/utils/user";
export default {
  props: {
    value: {
      type: [String, Array],
      default: () => {
        return "";
      },
    },
    dictType: {
      type: String,
      default: () => {
        return "";
      },
    },
    labelString: {
      type: String,
      default: () => {
        return "dictLabel";
      },
    },
    valueString: {
      type: String,
      default: () => {
        return "dictValue";
      },
    },
    defaultLabel: {
      type: String,
      default: () => {
        return "";
      },
    },
    resetKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    beforeOpenCheck: {
      type: Function,
    },
    data: {
      type: Array,
      default: () => [],
    },
    single: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
    directReturn: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Header,
    Search,
    BlockSelect,
    PlantSelect,
    SalesmanSelect,
    TerminalSelect,
    ReceiveSelect,
    DischargeSelect,
    GrowerSelect,
    ModelFieldSelect,
    BrandSelect,
    ProductSelect: () => import("@/views/select/productDetailSelect.vue"),
  },
  data() {
    return {
      pickerLabel: "",
      searchTmp: "",
      searchTotal: "",
      dictList: [],
      isShowPicker: false,
      // 定制化
      dealerList: [], // 经销商
      isShowDealerPicker: false,
      plantList: [], // 作物覆盖
      isShowPlantPicker: false,
      growerList: [], //种植大户选择
      isShowGrowerModal: false,
      modelFieldList: [], // 示范田选择
      isShowModelFieldModal: false,
      brandList: [], // 品牌选择
      isShowBrandModal: false,
      salesmanList: [], // 属地业务员
      isShowBusinessManPicker: false,
      terminalList: [], // 终端
      isShowTerminalPicker: false,
      receiveList: [], // 收货地址
      isShowReceiveAddress: false,
      dischargeList: [], // 卸货地址
      isShowDischargeAddress: false,
      productList: [], // 产品选择
      isShowProductSelect: false,
    };
  },
  computed: {
    pickerData() {
      return [...this.data, ...this.dictList].filter((item) => {
        if (typeof item === "string") return item.includes(this.searchTotal);
        return item[this.labelString].includes(this.searchTotal);
      });
    },
    blockSelectUserType() {
      if (this.$route.name.includes("Shidanli")) {
        return "shidanli";
      } else {
        return "ziyou";
      }
    },
  },
  watch: {
    value() {
      if (
        (this.single && !this.value) ||
        (!this.single && !this.value.length)
      ) {
        this.pickerLabel = "";
      }
    },
    plantList() {
      if (this.plantList.length > 0) {
        this.pickerLabel = this.plantList
          .map((item) => item.plantName)
          .join(",");
        this.$emit("input", this.pickerLabel);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    growerList() {
      if (this.growerList.length > 0) {
        this.pickerLabel = this.growerList[0].bigfarmersPerson;
        this.$emit("input", this.growerList[0].id);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    modelFieldList() {
      if (this.modelFieldList.length > 0) {
        this.pickerLabel = this.modelFieldList[0].modelFieldName;
        this.$emit("input", this.modelFieldList[0].id);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    brandList() {
      if (this.brandList.length > 0) {
        this.pickerLabel = this.brandList
          .map((item) => item.dictValue)
          .join(",");
        this.$emit("input", this.pickerLabel);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    dealerList() {
      if (this.dealerList.length > 0) {
        this.pickerLabel = this.dealerList[0].dealerName;
        this.$emit("input", this.dealerList[0].dealerId);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    salesmanList() {
      if (this.salesmanList.length > 0) {
        this.pickerLabel = this.salesmanList[0].nickName || "";
        this.$emit("input", this.salesmanList[0].userId);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    terminalList() {
      if (this.terminalList.length > 0) {
        this.pickerLabel =
          this.terminalList[0].contactPerson ||
          this.terminalList[0].dealerName ||
          this.terminalList[0].nickName;
        this.$emit("input", this.terminalList[0].id);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    receiveList() {
      if (this.receiveList.length > 0) {
        this.pickerLabel = this.receiveList[0].tranAddress;
        this.$emit("input", this.receiveList);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    dischargeList() {
      if (this.dischargeList.length > 0) {
        this.pickerLabel = this.dischargeList[0].tranAddress;
        this.$emit("input", this.dischargeList);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
    productList() {
      // console.log(this.productList);
      if (this.productList.length > 0) {
        this.pickerLabel = this.productList[0].materialName;
        this.$emit("input", this.productList);
      } else {
        this.pickerLabel = "";
        this.$emit("input", "");
      }
    },
  },
  mounted() {
    if (
      this.dictType &&
      ![
        "dealer",
        "plantSelect",
        "salesman",
        "terminal",
        "grower",
        "modelField",
        "brand",
        "receive",
        "discharge",
      ].includes(this.dictType)
    ) {
      this.fetchDictList();
    }
  },
  activated() {
    this.closePicker();
  },
  methods: {
    async fetchDictList() {
      let response;
      // 省市县村单独处理
      if (this.dictType === "province") {
        response = await commonApi.provinceList();
      } else if (this.dictType === "city") {
        response = await commonApi.cityList();
      } else if (this.dictType === "region") {
        response = await commonApi.regionList();
      } else if (this.dictType === "regionByDealer") {
        response = await commonApi.regionList({ dealer: getDealerId() });
      } else {
        response = await commonApi.dictList({
          type: this.dictType,
        });
        util.setNameMap(this.dictType, response.data);
      }
      this.dictList = response.data;
    },
    closePicker() {
      this.searchTmp = "";
      this.searchTotal = "";
      this.isShowPicker = false;
      this.isShowDealerPicker = false;
      this.isShowPlantPicker = false;
      this.isShowGrowerModal = false;
      this.isShowModelFieldModal = false;
      this.isShowBrandModal = false;
      this.isShowBusinessManPicker = false;
      this.isShowTerminalPicker = false;
      this.isShowReceiveAddress = false;
      this.isShowDischargeAddress = false;
      this.isShowProductSelect = false;
    },
    openPciker() {
      const errMsg = this.beforeOpenCheck && this.beforeOpenCheck();
      if (errMsg) {
        this.$toast(errMsg);
        return;
      }
      switch (this.dictType) {
        case "dealer": {
          this.isShowDealerPicker = true;
          break;
        }
        case "plantSelect": {
          this.isShowPlantPicker = true;
          break;
        }
        case "grower": {
          this.isShowGrowerModal = true;
          break;
        }
        case "modelField": {
          this.isShowModelFieldModal = true;
          break;
        }
        case "brand": {
          this.isShowBrandModal = true;
          break;
        }
        case "salesman": {
          this.isShowBusinessManPicker = true;
          break;
        }
        case "terminal": {
          this.isShowTerminalPicker = true;
          break;
        }
        case "receive": {
          this.isShowReceiveAddress = true;
          break;
        }
        case "discharge": {
          this.isShowDischargeAddress = true;
          break;
        }
        case "product": {
          this.isShowProductSelect = true;
          break;
        }
        default: {
          this.isShowPicker = true;
          break;
        }
      }
    },
    onClick(val, label) {
      this.pickerLabel = label;
      if (!this.single && this.value.indexOf(val) !== -1) {
        this.value.splice(this.value.indexOf(val), 1);
        val = this.value;
      } else if (!this.single && this.value.indexOf(val) === -1) {
        this.value.push(val);
        val = this.value;
      }
      this.$emit("input", val);
      // 联动处理
      this.$emit("on-change", val);
      // 重置某些value
      this.resetKeys.forEach((key) => {
        this.$emit("reset", key);
      });
      if (this.directReturn) {
        this.closePicker();
      }
    },
    reset() {
      this.$emit("input", "");
    },
    // 特殊处理
    async fetchCity(id) {
      const response = await commonApi.cityList({
        id,
      });
      this.dictList = response.data;
    },
    async fetchRegion(id) {
      const response = await commonApi.regionList({
        id,
      });
      this.dictList = response.data;
    },
    async fetchRegionByDealer(id) {
      const response = await commonApi.regionLevel({
        id,
        type: '3'
      });
      this.dictList = response.data;
    },
    async fetchTown(id) {
      const response = await commonApi.townList({
        id,
      });
      this.dictList = response.data;
    },
    async fetchVillage(id) {
      const response = await commonApi.villageList({
        id,
      });
      this.dictList = response.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.common-picker {
  .content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2c2c2c;
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #cecece;
      &.bold {
        color: #2c2c2c;
      }
    }
    .arrow-right {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
  }
  .picker-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    overflow-y: auto;
    background-color: #ffffff;
    .search-container {
      padding: 12px 24px;
    }
    .picker-item {
      padding: 12px 24px;
      text-align: left;
      &.active {
        background-color: #cd001f;
        color: #ffffff;
      }
    }
    /* 作物覆盖 */
    ::v-deep .plant-select-wrap .cell {
      text-align: center;
    }
  }
}
</style>
